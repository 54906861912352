var navBar = document.querySelector("#nav-items");
var iconSwitchOn = document.querySelector("#toggleOn");
var iconSwitchOff = document.querySelector("#toggleOff");
function navSwitch() {
    if (navBar.style.display === "flex" &&
        iconSwitchOff.style.display === "inline-block") {
        navBar.style.display = "none";
        iconSwitchOff.style.display = "none";
        iconSwitchOn.style.display = "inline-block";
    }
    else {
        navBar.style.display = "flex";
        iconSwitchOff.style.display = "inline-block";
        iconSwitchOn.style.display = "none";
    }
}
var navBtn = document.querySelector("#navToggle");
navBtn.addEventListener("click", navSwitch);
window.addEventListener("resize", function () {
    if (window.matchMedia("(min-width: 1124px)").matches && navBar.style.display === "none") {
        navBar.style.display = "flex";
    }
});
//This adds the highlight to the navigation elements
//const navLinks = document.querySelectorAll(".js-main-nav-link");
//navLinks.forEach((navLink: HTMLElement) => {
//    if (window.location.pathname === navLink.dataset.navLink) {
//        navLink.classList.add("text-selective-yellow");
//    }
//});
//this is for the timeline
var currentEventIndex = 0;
var timeline = document.getElementById('timeline');
var nextButton = document.getElementById('nextButton');
var prevButton = document.getElementById('prevButton');
var eventWidth = 235;
var eventMargin = 15;
var maxThreshold = 5;
var threshold = maxThreshold;
var totalEvents = 0;
var startX = 0;
var currentTranslate = 0;
var prevTranslate = 0;
var isDragging = false;
if (timeline !== null) {
    var eventElements = timeline.querySelectorAll('.timeline-card');
    totalEvents = eventElements.length;
    prevButton.style.backgroundColor = "grey";
    prevButton.disabled = true;
    function updateThreshold() {
        var viewportWidth = window.innerWidth - 120;
        var adjustedEventWidth = eventWidth + eventMargin;
        var calculatedThreshold = Math.floor(viewportWidth / adjustedEventWidth);
        threshold = Math.min(Math.max(calculatedThreshold, 1), maxThreshold);
        updateCarouselWidth();
        setButtonStates();
    }
    function updateCarouselWidth() {
        var visibleWidth = threshold * (eventWidth + eventMargin + 8);
        var timelineWrapper = document.querySelector('.timeline-container');
        if (timelineWrapper) {
            timelineWrapper.style.width = "".concat(visibleWidth, "px");
        }
        updateTimelinePosition();
    }
    function setButtonStates() {
        var maxScrollIndex = Math.max(totalEvents - threshold, 0);
        prevButton.disabled = currentEventIndex === 0;
        prevButton.style.backgroundColor = prevButton.disabled ? "grey" : "";
        nextButton.disabled = currentEventIndex >= maxScrollIndex;
        nextButton.style.backgroundColor = nextButton.disabled ? "grey" : "";
    }
    function slideNext() {
        var maxScrollIndex = Math.max(totalEvents - threshold, 0);
        if (currentEventIndex < maxScrollIndex) {
            currentEventIndex += threshold;
            if (currentEventIndex > maxScrollIndex) {
                currentEventIndex = maxScrollIndex;
            }
            updateTimelinePosition();
            setButtonStates();
        }
    }
    function slidePrev() {
        if (currentEventIndex > 0) {
            currentEventIndex -= threshold;
            if (currentEventIndex < 0) {
                currentEventIndex = 0;
            }
            updateTimelinePosition();
            setButtonStates();
        }
    }
    function updateTimelinePosition() {
        var offset = -currentEventIndex * (eventWidth + eventMargin);
        timeline.style.transform = "translateX(".concat(offset, "px)");
    }
    nextButton.addEventListener('click', slideNext);
    prevButton.addEventListener('click', slidePrev);
    timeline.addEventListener('touchstart', function (event) {
        isDragging = true;
        startX = event.touches[0].clientX;
        prevTranslate = currentTranslate;
    });
    timeline.addEventListener('touchmove', function (event) {
        if (!isDragging)
            return;
        var currentX = event.touches[0].clientX;
        currentTranslate = prevTranslate + currentX - startX;
        timeline.style.transform = "translateX(".concat(currentTranslate, "px)");
    });
    timeline.addEventListener('touchend', function () {
        isDragging = false;
        var movedBy = currentTranslate - prevTranslate;
        if (movedBy < -eventWidth / 3 && currentEventIndex < totalEvents - threshold) {
            slideNext();
        }
        else if (movedBy > eventWidth / 3 && currentEventIndex > 0) {
            slidePrev();
        }
        else {
            updateTimelinePosition();
        }
    });
    timeline.addEventListener('mousedown', function (event) {
        isDragging = true;
        startX = event.clientX;
        prevTranslate = currentTranslate;
    });
    window.addEventListener('mousemove', function (event) {
        if (!isDragging)
            return;
        var currentX = event.clientX;
        currentTranslate = prevTranslate + currentX - startX;
        timeline.style.transform = "translateX(".concat(currentTranslate, "px)");
    });
    window.addEventListener('mouseup', function () {
        if (!isDragging)
            return;
        isDragging = false;
        var movedBy = currentTranslate - prevTranslate;
        if (movedBy < -eventWidth / 3 && currentEventIndex < totalEvents - threshold) {
            slideNext();
        }
        else if (movedBy > eventWidth / 3 && currentEventIndex > 0) {
            slidePrev();
        }
        else {
            updateTimelinePosition();
        }
    });
    window.addEventListener('resize', function () {
        updateThreshold();
    });
    window.addEventListener('load', function () {
        updateThreshold();
    });
}
var form = document.getElementById('myform');
if (form !== null) {
    //document.getElementById('myform').querySelector('form').addEventListener('submit', function (event) {
    //    if (this.classList.contains('disabled')) {
    //        event.preventDefault();
    //        return;
    //    }
    //    this.classList.add('disabled');
    //    const inputs = document.querySelectorAll('input:not([type="hidden"]), textarea');
    //    let valid = true;
    //    inputs.forEach(i => {
    //        const inputElement = i as HTMLInputElement | HTMLTextAreaElement;
    //        const errorSpan = i.nextElementSibling;
    //        if (errorSpan) {
    //            if (!inputElement.value.trim()) {
    //                console.log("I found an error...", { i, errorSpan, });
    //                i.classList.add('border-red', 'text-red');
    //                errorSpan.classList.remove('hidden');
    //                valid = false;
    //            } else {
    //                i.classList.remove('border-red', 'text-red');
    //                errorSpan.classList.add('hidden');
    //            }
    //        }
    //    });
    //    if (valid) {
    //        //Do nothing, let the form submit!
    //    } else {
    //        this.classList.remove('disabled');
    //        event.preventDefault();
    //    }
    //});
    function onlyNumberKey(event) {
        var charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            event.preventDefault();
        }
    }
    window.onload = function () {
        var myInput = document.getElementById('phoneNum');
        myInput.addEventListener('keypress', onlyNumberKey);
    };
}
